import React, { useContext } from "react";
import { CompetenciesToAlignContext } from "../../_lib/contexts";
import { StandardContext } from "../../_lib/contexts/StandardContext";

function CompetencyTreeCheckbox({ edit, item, idx }) {
	// ---- contexts
	const { alignedCompetencies, addAlignment, removeAlignment } = useContext(CompetenciesToAlignContext);
	const { currentStandard } = useContext(StandardContext);

	// ---- item data
	const compItem = {
		Key: item.Key,
		Description: item.Description,
	};
	const key = item?.Key;

	// ---- item helper functions
	/**
	 * Is the given competency already aligned to the current standard?
	 * @param {string} theKey - Key of the competency to determine it's alignment to the current standard
	 * @returns {boolean}
	 */
	const isAligned = (theKey) => currentStandard?.CompetencyKeys.some((c) => c == theKey);

	const inRemoveArray = alignedCompetencies?.removedCompetencies.includes(item);
	/**
	 * Determines whether the current competency item is present in the array of aligned competencies
	 * @type {boolean}
	 */
	const isPresent = edit
		? alignedCompetencies?.editedCompetencies?.some((comp) => comp?.Key == key)
		: alignedCompetencies?.addedCompetencies?.some((comp) => comp?.Key == key);

	/**
	 * Handles alignment of checked item when form is in "Edit" mode
	 * @param {*} item competency that was checked
	 */
	function checkEdit(item) {
		const comps = alignedCompetencies?.editedCompetencies;
		const duplicate = (filter) => comps.some((comp) => comp?.Key == filter?.Key);

		if (!duplicate(item)) {
			addAlignment(item, edit);
		}
	}

	/**
	 * Handles alignment of checked item when no competencies have been aligned to the standard yet
	 * (i.e. form is not in "Edit" mode)
	 * @param {*} item competency that was checked
	 */
	function check(item) {
		const comps = alignedCompetencies?.addedCompetencies;
		const duplicate = (filter) => comps.some((comp) => comp.Key == filter.Key);

		if (!duplicate(item)) {
			addAlignment(item);
		}
	}

	/**
	 * Handles removal of the un-checked item when form is in "Edit" mode
	 * @param {*} item competency that was un-checked
	 */
	function uncheckEdit(item) {
		removeAlignment(item, edit);
	}

	/**
	 * Handles removal of un-checked item from addedCompetencies array when form is not in "Edit" mode
	 * @param {*} item competency that was un-checked
	 */
	function uncheck(item) {
		removeAlignment(item);
	}

	/**
	 * Handles the checkbox input for aligning / un-aligning competencies
	 * @param {*} item competency being checked
	 * @param {*} e event
	 */
	function handleCheck(item, e) {
		const checked = e.target.checked;

		checked && edit ? checkEdit(item) : checked ? check(item) : null;
		!checked && edit ? uncheckEdit(item) : !checked ? uncheck(item) : null;
	}

	return (
		<>
			{edit ? (
				<input
					data-testid={"checkbox"}
					id={item.Description}
					onClick={(e) => handleCheck(compItem, e)}
					key={idx}
					className="pointer"
					type="checkbox"
					checked={isPresent && !inRemoveArray}
					disabled={!isPresent && isAligned(key) && !inRemoveArray ? 1 : 0}
					readOnly
				/>
			) : (
				<input
					data-testid={"checkbox"}
					id={item.Description}
					onClick={(e) => handleCheck(compItem, e)}
					key={idx}
					className="pointer"
					type="checkbox"
					checked={isPresent && !inRemoveArray}
					disabled={isAligned(key)}
					readOnly
				/>
			)}
		</>
	);
}

export default React.memo(CompetencyTreeCheckbox);
