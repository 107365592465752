import React, { useContext, useReducer, useEffect, useState } from "react";
import { getAncestors, getTitleFromTuple } from "../../_helpers/functions";
import { CompetencyContext } from "../../_lib/contexts/CompetencyContext";
import { CompetencyRootContext } from "../../_lib/contexts/CompetencyRootContext";
import { graphTreeInitialState, treeReducer } from "../../_reducers/treeReducer";
import { LoadingCircle } from "../MaterialsUI";
import CompetencyTreeCheckbox from "./CompetencyTreeCheckbox";
import CreateChildCompetencyBtn from "./CreateChildCompetencyBtn";
import { Modal } from "../MaterialsUI";
import NewCompetencyForm from "../Competencies/Forms/NewCompetencyForm";

const CompetencyTree = ({
	data,
	depth,
	className,
	error,
	isError,
	select = false,
	selecting = true,
	checker = false,
	edit = false,
	drag = true,
	modal = false,
	innerRef,
	upperRef,
	hasNextPage,
	hasPrevPage,
	loadingNextPage,
	loadingPrevPage,
}) => {
	const [state, dispatch] = useReducer(treeReducer, graphTreeInitialState);
	const { competency, setCompetency } = useContext(CompetencyContext);
	// eslint-disable-next-line no-unused-vars
	const [root, setRootState] = useContext(CompetencyRootContext);
	const currentTitle = getTitleFromTuple(competency)?.Text;
	const currentKey = competency?.Key;
	const [show, setShow] = useState(false);

	function handleOpen(name) {
		dispatch({
			type: "OPEN",
			name,
		});
	}

	function handleClose(item) {
		dispatch({
			type: "CLOSE",
			item,
		});
	}

	// Figure out root change if root is in competency's parents
	function handleClick(item, num) {
		const newState = { ...item, num };
		if (select) {
			setRootState(newState);
		}
		setCompetency(newState);
	}

	useEffect(() => {
		let mounted = true;

		if (competency && selecting && mounted) {
			const parents = getAncestors(data, currentKey, 15);

			if (parents?.length) parents.forEach((c) => handleOpen(c.Key));

			handleOpen(currentKey);
		}

		return function cleanupFn() {
			mounted = false;
		};
	}, [competency]);

	if (isError) {
		// Will return the error Modal
		return <div className="d-flex justify-content-center align-content-center h-full w-full">{`Error: ${error}`}</div>;
	}

	if (!data) {
		return "Oops something went wrong with the server";
	}

	return (
		<>
			{depth > 0 ?? (
				<div className="d-flex justify-content-center my-3">
					{loadingPrevPage ? (
						<LoadingCircle loading={loadingPrevPage} modal={false} />
					) : (
						!hasPrevPage && "You are at the Start of Data"
					)}
				</div>
			)}

			{Array.isArray(data) && data.length >= 0 ? (
				data.flat().map((item, idx) => {
					const children = item?.Children;
					const title = getTitleFromTuple(item)?.Text;
					const key = item?.Key;
					const num = item?.PeopleId;
					const isSelected = competency && key == currentKey;
					const shouldRenderCreateChildCompetencyBtn = isSelected && !edit && !modal && depth === 0;
					const itemKeyDesc = {
						Key: item?.Key,
						Description: item?.Definition[0].Text,
						Children: children,
					};

					const shouldRenderCheckbox = depth > 0 && checker;
					return (
						<div key={idx} id={`${idx}_${depth}`}>
							<div
								className={`
									 ${className ?? ""} item ${children?.length > 0 ? "font-900" : "font-500"}
									 ${isSelected && selecting && "selected-item"}
								 	 ${state.currentItem.includes(key) && checker && "bg-light-themed "}
									 ${isSelected ? "selected-bg selected-item" : "bg-light-themed"}
									 `}
								data-depth={depth}
								data-comp-key={key}
								data-comp-rev={item.Revision}
								data-testid={`${idx}_${depth}`}>
								<div>
									<div className="d-flex align-items-center">
										{!state.currentItem.includes(key) ? (
											<button
												data-testid="open"
												className={`btn-outline-* pointer border-0 ${
													isSelected && selecting && " text-white"
												} text ${!children?.length && "cursor-default"}`}
												onClick={() => handleOpen(key)}>
												<div className="block">
													<i className={`material-icons ${!children?.length && "invisible"}`}>add</i>
												</div>
											</button>
										) : (
											<button
												data-testid="close"
												className={`
													btn-outline-*
													pointer border-0
													${isSelected && selecting && " text-white"}
													text
													${!children?.length && "cursor-default"}
													`}
												onClick={() => handleClose(key)}>
												<div className="block">
													<i className={`material-icons ${!children?.length && "invisible"}`}>remove</i>
												</div>
											</button>
										)}
										<div
											className={`
												${children?.length > 0 ? "font-bold" : "font-400"}
												text-capitalize
												d-flex
												my-half 
												align-items-center 
												w-full 
												`}
											ref={data.flat(1)?.length == idx + 1 ? innerRef : idx == 0 ? upperRef : null}
											onClick={() => !checker && handleClick(item, num)}>
											{shouldRenderCheckbox && <CompetencyTreeCheckbox item={itemKeyDesc} edit={edit} idx={idx} />}
											<div className={`mb-0 competency-container d-flex ${checker ? "cursor-default" : "pointer"}`}>
												{select ? (
													`${title}`
												) : (
													<>
														<p className="mb-0 mx-3 text-white people-id">{num}</p>
														<p className="mb-0">{title}</p>
														{shouldRenderCreateChildCompetencyBtn ? (
															<CreateChildCompetencyBtn showModal={() => setShow(true)} />
														) : (
															<></>
														)}
													</>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>

							{state.currentItem.includes(key) && children?.length > 0 && (
								<CompetencyTree
									id={title}
									data={children}
									modal={modal}
									depth={depth + 1}
									selecting={selecting}
									checker={checker}
									className={state.currentItem.includes(key) && checker && "bg-light-themed w-full"}
									drag={drag}
									edit={edit}
								/>
							)}
						</div>
					);
				})
			) : (
				<div className="list-item" data-depth={depth}>
					{data.length > 0 && (
						<div className={` ${getTitleFromTuple(data)?.Text == currentTitle && selecting && "selected-item w-full"} `}>
							{getTitleFromTuple(data)?.Text}
							{data.Children.length > 0 && (
								<CompetencyTree
									data={data.Children}
									depth={depth + 1}
									selecting={selecting}
									checker={checker}
									drag={drag}
									modal={modal}
									edit={edit}
									innerRef={innerRef}
									upperRef={upperRef}
									hasNextPage={hasNextPage}
									hasPrevPage={hasPrevPage}
									loadingNextPage={loadingNextPage}
									loadingPrevPage={loadingPrevPage}
								/>
							)}
						</div>
					)}
				</div>
			)}

			<Modal show={show}>
				<NewCompetencyForm show={show} setShow={() => setShow(false)} parentCompetency={competency} />
			</Modal>

			{depth == 0 && (
				<div className="d-flex justify-content-center my-3">
					{loadingNextPage ? <LoadingCircle loading={loadingNextPage} modal={false} /> : !hasNextPage && "All Data Loaded"}
				</div>
			)}
		</>
	);
};

export default CompetencyTree;
