import React, { useContext, useEffect, useState } from "react";
import { sanitizeCompetencyAlignment } from "../../_helpers/formUtils";
import { decodeHTML } from "../../_helpers/functions";
import useAuth from "../../_hooks/useAuth";
import { useAlignCompetenciesToStandard } from "../../_hooks/useStandardsAlignment";
import useTheme from "../../_hooks/useTheme";
import { CompetenciesToAlignContext, ErrorContext, SaveContext, StandardsAlignmentDocumentContext } from "../../_lib/contexts";
import { ConfirmOrCancelModal } from "../UI";
import CompetencyAlignmentForm from "./forms/CompetencyAlignmentForm";
import { StandardContext } from "../../_lib/contexts/StandardContext";
import DOMPurify from "dompurify";

function CompetencyAlignmentModal({ show, setShow, currentStandard, alignmentData, handleResetOrEdit, edit = false }) {
	const [showDiscard, setShowDiscard] = useState(false);

	const { alignedCompetencies, setAlignment } = useContext(CompetenciesToAlignContext);
	const { addError, isError, setIsErrorState } = useContext(ErrorContext);
	const { isSaved, isLoading, addLoadingMessage, loading, removeSaveModal } = useContext(SaveContext);
	const [currentDocument] = useContext(StandardsAlignmentDocumentContext);
	//eslint-disable-next-line
	const [standardDocument, setStandard] = useContext(StandardContext);

	const { mutateAsync: alignCompetencies } = useAlignCompetenciesToStandard();

	const { isNightTheme, isSunsetTheme } = useTheme();
	const { currentUser } = useAuth();
	const standardTitle = decodeHTML(DOMPurify.sanitize(currentStandard.Description));
	const standardPath = currentStandard.Path;
	const standardVersion = currentStandard.Version;
	const alignmentVersion = alignmentData && alignmentData[0].StandardVersion;
	const areVersionsEqual = standardVersion == alignmentVersion;
	const alignedCompetencyData = alignmentData && alignmentData[0]?.CompDescriptions;

	// if alignment data exists for the current standard,
	// set the current standard's alignment context
	if (!!alignmentData && currentStandard?.Key == alignmentData[0]?.Key) {
		setStandard(alignmentData[0]);
	}

	async function handleAlignment() {
		setShow(!show);
		isLoading();
		addLoadingMessage("Aligning Competencies...");

		const body = sanitizeCompetencyAlignment(currentStandard, alignedCompetencies.addedCompetencies);
		const standard_uuid = currentStandard.Id;
		const map_key = currentDocument.Key;

		const options = {
			headers: { "X-Arjuna-Initiator": currentUser.name },
			body,
			map_key,
			standard_uuid,
		};

		try {
			await alignCompetencies(options);
		} catch (e) {
			setIsErrorState();
			addError(e.message, e.statusCode);
			removeSaveModal();
		}

		if (!loading && !isError) {
			setTimeout(() => isSaved(), 1500);
		}
	}

	useEffect(() => {
		if (alignmentData) {
			setAlignment(alignedCompetencyData);
		}
	}, []);

	// Component to list new competencies to be added/aligned
	const addedCompetencies =
		alignedCompetencies?.addedCompetencies?.length > 0 &&
		alignedCompetencies.addedCompetencies.map((comp, index) => {
			const title = comp?.Description;

			return (
				<p className={`pr-3 mb-0 text-base ${isNightTheme ? "color-blue" : "color-purple"}`} data-testid="added" key={index}>
					+ {title}
				</p>
			);
		});

	// Component to list existing aligned competencies (EDIT mode only)
	const alignedCompetenciesComponent =
		!!alignmentData &&
		alignmentData[0]?.CompDescriptions?.length > 0 &&
		alignmentData[0]?.CompDescriptions.map((comp, index) => {
			const title = comp?.Description;
			let toBeRemoved = false;

			if (alignedCompetencies?.removedCompetencies?.length > 0) {
				alignedCompetencies.removedCompetencies.forEach((removedComp) => {
					if (removedComp.Key == comp.Key) toBeRemoved = true;
				});
			}

			return (
				<p className={`pr-3 mb-0 text-base  ${toBeRemoved && "crossed-out"}`} data-testid="removed" key={index}>
					- {title}
				</p>
			);
		});

	return (
		<>
			{showDiscard ? (
				<ConfirmOrCancelModal
					className="background rounded p-4"
					onClickCancel={() => {
						setShowDiscard(false);
					}}
					onClickConfirm={() => {
						setShow(!show);
					}}>
					Confirm that you want to discard the current draft. All edits will be lost.
				</ConfirmOrCancelModal>
			) : (
				<div
					className={`editor w-full shadow-1 p-4 ${isSunsetTheme ? "text-white irisBackground" : "background"}`}
					onClick={(e) => e.stopPropagation()}>
					{edit ? (
						areVersionsEqual ? (
							<p className="m-0 border-rounded bg-muted px-half">Standard &amp; Alignment v{alignmentVersion}</p>
						) : (
							<p className="m-0 border-rounded bg-red px-half">
								Standard v{standardVersion} != Alignment v{alignmentVersion}
							</p>
						)
					) : null}
					<div className="d-flex justify-content-start w-full flex-column">
						<div className={"text font-400 text-lg d-flex pb-2"}>
							<p className="m-0 tracing-1">Standard:</p>
							<p className="px-3 m-0 tracing-1">{standardPath}</p>
							<p className="m-0 pr-3" dangerouslySetInnerHTML={{ __html: standardTitle }}></p>
						</div>
						<div className="text font-400 text-lg d-flex w-full">
							<p className="m-0 tracing-1 pr-3">Aligned Competencies:</p>

							<div className="p-1 bg-light-themed d-flex text-truncate-flex flex-column aligned-competencies-box overflow-y">
								{edit ? (
									<>
										{alignedCompetenciesComponent}
										{addedCompetencies}
									</>
								) : alignedCompetencies?.addedCompetencies?.length > 0 ? (
									addedCompetencies
								) : (
									<p className="pr-3 mb-0 text-base">none selected</p>
								)}
							</div>
						</div>
					</div>
					<CompetencyAlignmentForm
						setShowDiscard={setShowDiscard}
						handleAlignment={handleAlignment}
						handleResetOrEdit={handleResetOrEdit}
						standardVersion={standardVersion}
						edit={edit}
					/>
				</div>
			)}
		</>
	);
}

export default CompetencyAlignmentModal;
